<template>
	<v-app id="inspire" class="app" style="display:none">
		<v-content>
			<v-container
				class="fill-height"
				fluid
			>
				<v-row
					align="center"
					justify="center"
				>
					<v-col
						cols="12"
						sm="8"
						md="4"
					>
						<v-card class="elevation-12">
							<v-toolbar
								color="primary"
								dark
								flat
							>
							<v-toolbar-title>翻译平台</v-toolbar-title>
								<v-spacer></v-spacer>
							</v-toolbar>
							<v-card-text>
								<v-form @keyup.enter.native="login()">
									<v-text-field
										v-model="username"
										label="Username"
										name="login"
										prepend-icon="person"
										type="text"
									></v-text-field>

									<v-text-field
										v-model="password"
										id="password"
										label="Password"
										name="password"
										prepend-icon="lock"
										type="password"
									></v-text-field>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" disabled @click="openLogin()">第三方登录</v-btn>
								<v-btn color="primary" @click="login()">登 录</v-btn>
								<!-- <v-btn color="primary" disabled @click="logout()">登 出</v-btn> -->
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-content>
	</v-app>
</template>

<script type="text/javascript">
	import crypto from "crypto"
	import $$ from "axios"
	import utils from "@/common/utils"

	export default {
		name : "v-login",
		data (){
			return {
				rules : [
					{
						required : true
					}
				],
				username : localStorage.getItem("userName") || "",
				password : localStorage.getItem("pas") || ""
			}
		},
		created (){			
			this.sdk.manager.event.un("login");
			this.sdk.on('login', () => {
				this.loginSuccess();
			});
			this.sdk.manager.event.un("logout");
			this.sdk.on('logout' , ()=>{
				this.logoutSuccess();
			})
			this.openLogin();
		},
		destoryed (){
			//this.sdk.off("login");
			//this.sdk.off("logout");
		},
		methods : {
			openLogin (){
				this.sdk.init();
				this.sdk.openLoginRegWindow({});
			},
			loginSuccess (){
				//login接口必须当域名在同一大域下，opcookie则是另外一个域名，登陆完成后两个域名直接通报种入OPSESSION，保持登陆同步
				$$.post(process.env.VUE_APP_HTTP_PRE + "/api-intl-translate/login").then(async data => {
					await $$.get(process.env.VUE_APP_HTTP_PRE_INNER + "/api-intl-translate/operation-anonymous/core/opcookie?cookie=" + utils.getCookie("OPSESSION"));
					this.$store.commit("setUser" , data);
					if(this.$route.query.path && this.$route.query.path.indexOf("iqiyi.com") !== -1 && this.$route.query.path.indexOf("javascript") === -1){
						location.href = this.$route.query.path;
					}else{
						this.$router.push("/")
					}
				}).catch(err => {
					this.$message.error(err.message)
				})
				
			},
			logoutSuccess(){
				$$.get("/api-intl-translate/logout").then( () => {
					utils.setCookie("OPSESSION" , "" , -1)
					//this.$router.push("/")
				}).catch(err => {
					this.$message.error(err.message)
				})
			},
			logout(){
				this.sdk.logout();
			},
			login(){
				if(!this.username){
					this.$message.warning("请填写用户名");
					return false;
				}
				if(!this.password){
					this.$message.warning("请输入密码");
					return false;
				}
				let form = new FormData();
				let md5 = crypto.createHash("md5");
				let pas = (localStorage.getItem("pas") && localStorage.getItem("pas") == this.password) ? localStorage.getItem("pas") : md5.update(this.password).digest("hex")

				form.append("username" , this.username);
				form.append("password" , pas)
				$$.post("/api-intl-translate/login" , form).then( data => {
					this.$store.commit("setUser" , data);
					localStorage.setItem("userName" , this.username);
					localStorage.setItem("pas" , pas);
					if(this.$route.query.path){
						location.href = this.$route.query.path;
					}else{
						this.$router.push("/")
					}
				}).catch(err => {
					this.$message.error(err.message)
				})
			}
		}
	};
</script>

<style type="scss" scoped="">
	.app {
		background-color: #f6f4f6
	}
</style>